import * as React from "react"
import {graphql} from 'gatsby'
import Seo from "../components/seo"
import GoodChaos from '../components/GoodChaos'
import Reveal from '../components/Reveal'


const PeoplePage = ({data}) => {

const mikeImage = data.allFile.nodes.find(node=> node.relativePath.includes('Mike'))
const jamesImage = data.allFile.nodes.find(node=> node.relativePath.includes('James'))

return (
<article>
  <Seo title="Who are GoodChaos?" />
  <h2><GoodChaos /> are:</h2>
  <section>
    <div>
      <Reveal delay={0.3} image={mikeImage} alt="Mike Goodridge"/>
      <h3>Mike Goodridge</h3>
      <div>
        <p>
        A nullam lectus leo, feugiat nulla orci, in tortor lacus, et, ornare aliquam massa volutpat libero lobortis cras nibh lobortis ultrices diam, commodo dignissim ac massa in massa, ultrices ullamcorper.
        </p>
      </div>
    </div>

    <div>
      <Reveal delay={0.8} image={jamesImage} alt="James Bowsher"/>
      <h3>James Bowsher</h3>
      <div>
        <p>
        Massa, mi, pellentesque posuere turpis nec donec ipsum risus, non et ut id arcu venenatis convallis mauris, diam eget dolor urna condimentum lacus scelerisque neque dolor sociis feugiat.
        </p>
      </div>
    </div>
  </section>
</article>
)
}

export default PeoplePage

export const pageQuery = graphql`
query {
  allFile {
    nodes {
      relativePath
      childImageSharp {
        gatsbyImageData(
          width: 500
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
}
`