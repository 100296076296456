import React from 'react'
import {goodChaosHolder, good, chaos} from '../styles/GoodChaos.module.scss'

export default function GoodChaos() {
  return (
    <span className={goodChaosHolder}>
      <span className={good}>Good</span><span className={chaos}>Chaos</span>
    </span>
  )
}
