import React from 'react'
import {revealHolder, revealDisc, imageHolder} from '../styles/Reveal.module.scss'
import {motion} from 'framer-motion'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

export default function Reveal({delay, image, alt}) {
  const variants = {
    pre: {
      background:'conic-gradient(rgba(255,255,255,0) 0turn 0turn, rgba(255,255,255,1) 0turn 1turn)'
    },
    visible: {
      background:'conic-gradient(rgba(255,255,255,0) 0turn 1turn, rgba(255,255,255,1) 1turn 1turn)',
      transition: {
        type: "tween",
        ease: [.94,.01,.25,.99],
        duration: 0.7,
        delay: delay
      }
    },
    post: {
      background:'conic-gradient(rgba(255,255,255,0) 0turn 0turn, rgba(255,255,255,1) 0turn 1turn)'
    }
  }

  return (
    <div className={revealHolder}>
    <div className={imageHolder}>
    <GatsbyImage image={getImage(image.childImageSharp.gatsbyImageData)} alt={alt} />
    </div>
    <motion.div variants={variants} initial='pre' animate='visible' exit='post' className={revealDisc}></motion.div>
    </div>
  )
}
